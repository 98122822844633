@import "./sass/mixins";
@import "./sass/variables";
@import "animate.css";
.App {
  // padding: 1% 3% 0 3%;
  position: relative;
  max-width: 140rem;
  margin: auto;
  @include respondMobileFirst(phone) {
    // padding: 3% 4%;
  }
  @include respondMobileFirst(tab-port) {
    // padding: 3% 10%;
  }
}
.container__content{
padding: 1% 3% 0 3%;
}
