@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";
.portalModal {
  // position: absolute;
  position: fixed;
  // this will place the div at the bottom of its parent
  top: 10rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  overflow: hidden;
  outline: 0;
  width: 100vw;
  height: 100%;
  background-color: $color-blue;
  color: $white-color;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  @include respondMobileFirst(phone) {
    display: none;
  }
}
.burger--item {
  padding: 1.5rem;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}
.container__modal {
  flex-direction: column;
  padding: 4rem;
  width: 80%;
  height: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  margin-top: 8rem;
  color: $white-color;
}
.align-end {
  align-self: flex-end;
}
