@import "../..//sass/mixins";
@import "../..//sass/variables";

.error__container {
  width: 100%;
  color: $color-blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error__title {
  font-size: 10rem;
  line-height: normal;
  font-weight: 700;
}
.error__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.error__back {
  border: 0;
  color: $color-blue;
  font-size: 1.8rem;
  font-weight: 500;
  text-decoration: underline;
}
