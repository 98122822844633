@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.work__item {
  background-color: #fff;
  box-shadow: 0px 0px 20px -5px rgb(0 0 0 / 75%);
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.work__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 0.5rem;
  @media only screen and (min-width: 1000px) {
    height: auto;
  }
  img {
    border-radius: 0.5rem;
    object-fit: cover;
    height: 100%;
  }
}
.tag__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}
.tag__item {
  display: inline-block;
  background-color: $color-blue;
  padding: 0.4rem 1rem;
  border-radius: 2rem;
  color: $white-color;
  min-width: 5rem;
  text-align: center;
  font-size: 1.5rem;
  @include respondMobileFirst(big-desktop) {
    font-size: 1.2rem;
  }
}
.img__overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 2rem;
  }
  svg {
    width: 50%;
    height: 50%;
    color: var(--white-color);
  }
}
.work__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 1.9rem;
}
.info__title {
  font-weight: 700;
  font-size: 2.1rem;
  color: $color-blue;
}
.info__description {
  margin-top: 1rem;
  font-weight: 500;
}
.work__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  a {
    color: $color-blue;
    font-size: 3.5rem;
    padding: 0 1rem;
  }
}
.work__phone__links {
  display: flex;
  @include respondMobileFirst(phone) {
    display: none;
  }
}
