@import "../../sass/variables.scss";
.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

.text-animate-hover {
  display: inline-block;
  animation-fill-mode: both;
  cursor: pointer;
  &:hover {
    animation: rubberBand 1s;
    color: $color-tertiary;
  }
}
