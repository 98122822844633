@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.work {
  margin: 10rem 0 1.5rem 0;
  padding-top: 2rem;
}
.work__title {
  font-size: 3rem;
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 2rem;
  span {
    color: $color-blue;
  }
}
.work__categories {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.work__container {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, 325px);
  gap: 3rem;
  border-radius: 2rem;
  margin-top: 2rem;
  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(auto-fill, 451px);
  }
}
.work--category.activeItem {
  background-color: $four-color;
  color: #fff;
}
.work__category {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #000;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0.5rem;
  &:hover {
    background-color: $four-color;
    color: #fff;
  }
}
