@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  padding: 0 2rem;
  h1 {
    font-size: 3rem;
    color: $color-blue;
    font-weight: 500;

    @include respondMobileFirst(phone) {
      font-size: 2rem;
    }
  }
}
.header__clicked{
  position: fixed;
  width: 100%;
  background-color: $white-color;
}
.header--nav {
  &__list {
    display: flex;
    gap: 1.5rem;
    font-size: 2rem;
    font-weight: 500;
    color: $color-blue;
  }
}

.header--nav__main {
  display: none;
  @include respondMobileFirst(phone) {
    display: block;
  }
}

.menu-open {
  display: block;
  width: 35px;
  cursor: pointer;
  @include respondMobileFirst(phone) {
    display: none;
  }
}
.menu-open:before,
.menu-open:after,
.menu-open div {
  background: $color-blue;
  content: "";
  display: block;
  height: 3px;
  border-radius: 3px;
  margin: 4px 0;
  transition: 0.5s;
}
.menu-open__clicked:before {
  transform: translateY(8px) rotate(135deg);
  background-color: $color-blue;
}
.menu-open__clicked:after {
  transform: translateY(-7px) rotate(-135deg);
  background-color: $color-blue;
}
.menu-open__clicked div {
  transform: scale(0);
}
.modal-menu {
  @include respondMobileFirst(phone) {
    display: none;
  }
}
.burger--nav {
  margin-top: 15px;
  position: absolute;
  display: none;
  color: $color-blue;
  background-color: $white-color;
  right: 0;
  padding: 20px;
  border-radius: 5%;
}
.burger--nav__clicked {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.burger--item {
  padding: 1.5rem;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}
// .container__modal {
//   flex-direction: column;
//   padding: 4rem;
//   width: 80%;
//   height: min-content;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 3rem;
//   margin-top: 8rem;
//   color: $color-tertiary;
//   // justify-content: space-around;
//   background-color: white;
// }
// .align-end {
//   align-self: flex-end;
// }
