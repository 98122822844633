@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.about {
  display: flex;
  flex-direction: column;
  animation: fade-in 0.3s ease-in-out forwards;
  justify-content: space-between;
  margin-top: 5rem;
  align-items: center;
  @media only screen and (min-width: 700px) {
    flex-direction: row;
  }
}
.about__text {
  margin-top: 2.5rem;
  line-height: 3rem;
}
.about__welcome {
  font-size: 3rem;
  flex-basis: 50%;
}
.welcome {
  margin-bottom: 1.5rem;
  color: $color-tertiary;
  > p {
    margin-bottom: 1.5rem;
  }
}
.welcome__portfolio {
  font-size: 8rem;
  font-weight: 700;
  line-height: 5rem;
  margin-top: 3rem;
  color: $color-blue;
  font-family: $font-secondary;
  @include respondMobileFirst(big-desktop) {
    font-size: 10rem;
  }
}
.welcome__jobDesc {
  margin-bottom: 1.5rem;
  display: flex;
  gap: 1rem;
}
.image__container {
  align-self: center;
  order: -1;
  margin-bottom: 2rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  width: 300px;
  height: 300px;
  @media only screen and (min-width: 700px) {
    order: 1;
  }
}
.about__img {
  border-radius: 50%;
  height: 80%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  background-color: $color-blue;
}

.whoIam__job {
  color: $white-color;
  font-size: 2rem;
}
.socials--list {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;

  @include respondMobileFirst(phone) {
    margin-top: 3rem;
  }
  li {
    background-color: $color-blue;
    padding: 0.7rem 1rem;
    border-radius: 12px;
    font-size: 1.5rem;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.9;
      transform: scale(0.9);
    }
  }
  a {
    display: flex;
    align-items: center;
    color: white;
    svg {
      margin-right: 0.5rem;
    }
  }
}
