@import "../../sass/variables";
.contact {
  width: 100%;
  margin-top: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
textarea {
  margin: 1rem 0;
}
input[type="text"],
input[type="email"] {
  width: 100%;
  border: 0;
  background: $color-blue;
  height: 50px;
  font-size: 16px;
  color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  border: 0;
  background: $color-blue;
  height: 50px;
  font-size: 16px;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  min-height: 150px;
}

.flat-button {
  color: #ffd700;
  font-size: 11px;
  letter-spacing: 3px;
  text-decoration: none;
  padding: 8px 10px;
  border: 1px solid #ffd700;
  float: left;
  border-radius: 4px;
  background: 0 0;
  text-transform: uppercase;
  float: right;
  text-align: center;
  margin-right: 10px;
}
