@import "../../sass/variables.scss";
.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 auto 0;
  margin-top: 10rem;
  padding-top: 2rem;
  h2{
    color: $color-blue;
  }
}
.skills__span {
  font-style: italic;
  color: $color-blue;
  margin-right: 0.4rem;
}
.skills__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  justify-content: space-between;
  align-content: center;
  gap: 3rem;
  border-radius: 2rem;
  padding: 2rem;
  width: 100%;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
}

.skills__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 1.5rem auto;
  padding: 1.5rem;
  transition: all 0.3s ease;
  background-color: #fff;
  box-shadow: 0px 0px 20px -5px rgb(0 0 0 / 75%);
  border-radius: 1rem;
  max-width: 185px;
  min-width: 158px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #fff;
  }
  img {
    width: 50%;
    height: 50%;
  }
}

.item__name {
  font-weight: 700;
  margin-top: 1rem;
  color: $color-blue;
}
