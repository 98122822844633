// PRIMARY COLORS
// $white-color: #fff;
$white-color: #FDFFFC;
$four-color: rgba(172, 17, 233, 0.274);
$color-blue: #2745c2;
$color-tertiary: #0C120C;


// FONTS WEIGHT
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// FONT FAMILY
$font-primary: "Montserrat", sans-serif;
$font-secondary: "Inter", sans-serif;
// FONTS SIZE
$font-heading-1: 4rem;
$font-size-body: 1.8rem;
$font-size-element: 14px;

// FONT LINE HEIGHT
$font-line-height-body: 26px;

